/**
 * @returns {boolean}
 */
export function isPopoverSupported() {
  return 'popover' in HTMLElement.prototype;
}

/**
 * @param {HTMLElement} element
 * @param {string} selector
 */
export function previousSibling(element, selector) {
  let previousSibling = element.previousElementSibling;

  while (previousSibling) {
    if (previousSibling.matches(selector)) {
      return previousSibling;
    }

    previousSibling = previousSibling.previousElementSibling;
  }

  return previousSibling;
}

/**
 * @param {HTMLElement} element
 * @param {string} selector
 */
export function nextSibling(element, selector) {
  let nextSibling = element.nextElementSibling;

  while (nextSibling) {
    if (nextSibling.matches(selector)) {
      return nextSibling;
    }

    nextSibling = nextSibling.nextElementSibling;
  }

  return nextSibling;
}

/**
 * @param {HTMLElement} element
 * @param {Function} callback
 * @returns {string}
 */
export function removeClass(element, callback) {
  element.className = element.className
    .split(/\s+/)
    .filter((className) => !callback(className))
    .join(' ');

  return element.className;
}

/**
 * @param {HTMLElement} element
 * @param {string} prefix
 * @returns {string}
 */
export function removeClassStartingWith(element, prefix) {
  return removeClass(element, (className) => className.startsWith(prefix));
}

/**
 * @param {HTMLElement} element
 * @param {string} suffix
 * @returns {string}
 */
export function removeClassEndingWith(element, suffix) {
  return removeClass(element, (className) => className.endsWith(suffix));
}