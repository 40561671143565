import {
  isPopoverSupported,
  nextSibling,
  removeClassEndingWith
} from './utilities/dom.js';

function loadPolyfills() {
  const polyfills = [];

  if (! isPopoverSupported()) {
    polyfills.push(import('@oddbird/popover-polyfill'));
  }

  return Promise.all(polyfills);
}

function expandElementContents(element) {
  element?.querySelectorAll('details').forEach((details) => {
    details.open = true;
    removeClassEndingWith(details, '--collapsed');
  });
}

function clearInputState(input) {
  const control = input.tagName === 'SELECT'
    ? input.parentElement
    : input;

  input.removeAttribute('aria-invalid');

  removeClassEndingWith(control, '--invalid');
  removeClassEndingWith(control, '--valid');

  nextSibling(control, '.inline-error')?.remove();
}

function scrollToStatusMessage(form) {
  const container = form?.closest('.container');
  const message = container?.querySelector('[role="alert"], [role="status"]');

  if (message) {
    expandElementContents(container);
    setTimeout(() => message.scrollIntoView(), 200);
  }
}

function bootApplication() {
  for (const anchor of document.querySelectorAll('a[href^="#"]')) {
    anchor.addEventListener('click', (event) => {
      expandElementContents(
        document.getElementById(event.target?.hash.slice(1))
      );
    });
  }

  for (const input of document.querySelectorAll('[aria-invalid]')) {
    input.addEventListener('input', (event) => {
      clearInputState(event.currentTarget)
    });
  }

  for (const form of document.querySelectorAll('form')) {
    scrollToStatusMessage(form);
  }
}

function handleExceptions(error) {
  console?.error(error);
}

loadPolyfills()
  .then(bootApplication)
  .catch(handleExceptions);